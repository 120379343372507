import axios from 'axios';
import router from '../router';
import store from '../store';

import Vue from 'vue';
import { TOAST_TYPES } from '@/data';
import { BASE_URL } from './apiVars';

let getToken = () => {
  return store && store.state['app/userData']
    ? 'Bearer ' + store.state['app/userData'].accessToken
    : sessionStorage.getItem('token')
    ? 'Bearer ' + sessionStorage.getItem('token')
    : '';
};

let headers = {
  'Content-Type': 'application/json',
  Authorization: getToken(),
};

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: headers,
});

const errorInterceptor = async error => {
  if (!error.response) {
    console.error('**Network/Server error');
    return Promise.reject(error);
  }

  // let firstError = error.response.data && error.response.data.errors ? error.response.data.errors.alert[0] : '';

  // let firstError = error.response.data.errors.alert[0];//errorInfo[Object.keys(errorInfo)[0]][0]
  // all the other error responses
  // console.log('first error', firstError);

  let allErrorsMsg = [];
  if (error.response && error.response.data && error.response.data.errors) {
    let errorInfo = error.response.data.errors;
    let errorKeys = Object.keys(errorInfo);
    errorKeys.forEach(key => {
      allErrorsMsg.push(`${key}: ${errorInfo[key]}`);
      allErrorsMsg.push('\n');
    });
    allErrorsMsg = allErrorsMsg.join('');
  }

  switch (error.response.status) {
    case 400:
      Vue.prototype.$toast.error(allErrorsMsg);
      break;
    case 401: {
      // authentication error, logout the user
      const refreshToken = sessionStorage.getItem('refresh');
      const originalRequestConfig = error.config;
      if (originalRequestConfig.url !== 'login') {
        if (!originalRequestConfig._retry) {
          originalRequestConfig._retry = true;
          try {
            store.dispatch('app/refreshToken', refreshToken);
          } catch (error) {
            return Promise.reject(error);
          }
        }
        return httpClient(originalRequestConfig);
      }
      return Promise.reject(error);
    }
    case 403:
      Vue.prototype.$toast.error('Sorry, you have not access to this page');
      break;
    case 500:
      Vue.prototype.$toast.error(allErrorsMsg);
      break;
    default:
      break;
  }
  return Promise.reject(error);
};

httpClient.interceptors.request.use(
  config => {
    config.headers['Authorization'] = getToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use({}, errorInterceptor);
export default httpClient;
