export default {
  defaultMenuLanguageId: {
    type: 'select',
  },
  defaultDeliveryMethod: {
    type: 'select',
  },
  imageSourceId: {
    type: 'select',
  },
  useDayLigthSavingTime: {
    type: 'checkbox',
  },
  useCustomTimeZone: {
    type: 'checkbox',
  },
  timeZone: {
    type: 'select',
  },
  delayOrderSaving: {
    type: 'checkbox',
  },
  isPayOrderEnabled: {
    type: 'checkbox',
  },
  isPrintOrderEnabled: {
    type: 'checkbox',
  },
  isAutoPrintEnabled: {
    type: 'checkbox',
  },
  isAutoPickedUpEnabled: {
    type: 'checkbox',
  },
  isUseComboName: {
    type: 'checkbox',
  },
  woltExpiredDateUtc: {
    type: 'datetime',
  },
  boltExpiredDateUtc: {
    type: 'datetime',
  },
  isAutoAcceptEnabled: {
    type: 'checkbox',
  },
  isAutoSyncMenuEnabled: {
    type: 'checkbox',
  },
  isSingleModifierModeEnabled: {
    type: 'checkbox',
  },
  isDebugMode: {
    type: 'checkbox',
  },
  autoAcceptTimeInSeconds: {
    type: 'number',
  },
  maxCookingTimeInMinutes: {
    type: 'number',
  },
  defaultDelayTimeInMinutes: {
    type: 'number',
  },
  isPayOrderAfterAcceptEnabled: {
    type: 'checkbox',
  },
  isUseKds: {
    type: 'checkbox',
  },
  alwaysDefaultDelayTime: {
    type: 'checkbox',
  },
};
